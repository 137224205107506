//import Scroll from './Scroll';
// import Parallax from './parallax';
//import Menu from './menu';

$(function ($) {
  //api 読み込み
  let apiUrl = 'https://famione.com/wp-json/wp/v2/specialist?per_page=100&_embed&_fields=id,title,_links,meta_field,tag_field&orderby=menu_order&order=asc';
  let memberHtml = '';
  $.ajax({
    url: apiUrl,
    type: 'GET',
    dataType: "json",
  }).done(function (data) {
    data.forEach((item) => {
      let specialtyList = '';
      let term = item['tag_field'];

      if (term) {
        specialtyList = '<dl class="bl_specialtyList"><dt>得意分野</dt>';
        term.forEach((tag) => {
          specialtyList += `<dd class="${tag['slug']}">${tag['name']}</dd>`;
        });
        specialtyList += `</dl>`;
      }
      memberHtml += `
        <div class="bl_slider">
          <div class="bl_sliderHeading">
            <figure class="bl_slider_img"><img src="${item['_embedded']['wp:featuredmedia'][0]['source_url']}" alt="${item['meta_field']['degree'][0]} ${item['title']['rendered']}" alt="" width="96" height="96" loading="lazy"></figure>
            <div>
              <div class="bl_slider_degree">${item['meta_field']['degree'][0]}</div>
              <div class="bl_slider_name">${item['title']['rendered']}</div>
            </div>
          </div>
          ${specialtyList}
          <p class="bl_slider_txt">${item['meta_field']['detail'][0]}</p>
        </div>
        `;
    })
    $('.js-memberList').html(memberHtml);

    $('.js-acSwitch').on('click', function (e) {
      var target = $(e.currentTarget);
      var parent = target.closest('.js-acParent');

      if (parent.hasClass('is-active')) {
        parent.removeClass('is-active');
        parent.find('.js-acTarget').slideUp(300);
      } else {
        parent.addClass('is-active');
        parent.find('.js-acTarget').slideDown(300);
      }
    });

    $('.js-slider').slick({
      arrows: true,
      dots: false,
      infinite: false,
      slidesToShow: 2,
      slidesToScroll: 2,
      dots: true,
      responsive: [{
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
    });

  }).fail(function (data) {
    // error
    console.log('error');
  });

  $('.js-tabSwitch').on('click', (e) => {
    let href = $(e.currentTarget).attr('href');
    $('.js-tabParent').attr('data-status', href);
    let position = $('.js-tabParent').offset().top;
    $('body,html').animate({ scrollTop: position - 100 }, 300, 'swing');
    return false;
  })

});
